import { store } from "react-notifications-component"

const show = options => {
  let message = options.message
  if (options.details && options.details.length > 0) {
    message = `${message} (${options.details.join(", ")})`
  }
  store.addNotification({
    animationOut: ["animated", "bounceOutRight"],
    animationIn: ["animated", "bounceInRight"],
    container: "top-right",
    insert: "top",
    dismiss: {
      duration: options.type === "danger" ? 10000 : 5000,
      showIcon: true,
    },
    ...options,
    message,
  })
}

const info = (title, message) => show({ type: "info", title, message })
const error = (title, message, details) => show({ type: "danger", title, message, details })
const success = (title, message) => show({ type: "success", title, message })
const basic = (title, message) => show({ type: "default", title, message })
const warning = (title, message) => show({ type: "success", title, message })

export default { info, error, success, basic, warning }
